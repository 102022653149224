const floorNumberRegex = /^(|M|B|MB)(\d+(?:\.\d+)?)F?$/;

export const tryFloorToNumber = (floor: string): number | undefined => {
  const matches = floor.match(floorNumberRegex);
  if (matches !== null) {
    const [, type, number] = matches;
    switch (type) {
      case "":
        return +number;
      case "B":
        return -number;
      case "M":
        return +number - 0.5;
      case "MB":
        return -number + 0.5;
      default:
        return;
    }
  } else {
    return;
  }
};

export const sortFloors = (floors: string[], ascending = false) => {
  return [...floors].sort((floorA, floorB) => {
    const floorANumber = tryFloorToNumber(floorA);
    const floorBNumber = tryFloorToNumber(floorB);
    if (
      typeof floorANumber === "undefined" &&
      typeof floorBNumber === "undefined"
    )
      return 0;
    else if (typeof floorANumber === "undefined") return 1;
    else if (typeof floorBNumber === "undefined") return -1;
    else
      return ascending
        ? floorANumber - floorBNumber
        : floorBNumber - floorANumber;
  });
};
