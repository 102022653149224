import {
  GraphQLAboutContractStartType,
  GraphQLCamFee,
  GraphQLCamFeeType,
  GraphQLContractPeriod,
  GraphQLContractPeriodType,
  GraphQLContractStart,
  GraphQLContractStartType,
  GraphQLContractType,
  GraphQLDeposit,
  GraphQLDepositType,
  GraphQLInternalRent,
  GraphQLInternalRentType,
  GraphQLKeyMoney,
  GraphQLLeasingType,
  GraphQLRent,
  GraphQLRentType,
  GraphQLUnitUse,
  GraphQLUnitUseElement,
  Maybe,
} from "../graphql";
import { formatDateStr, formatMonthStr } from "./DateFormatter";
import { formatLocaleDecimal, removeTrailingZeros } from "./DecimalUtils";
import { sortFloors } from "./sortFloors";

export const formatContractStart = (contractStart: {
  date?: string;
  kind: "unknown" | "negotiable" | "approxDate" | "exactDate";
  period?: "unknown" | "early" | "middle" | "late";
}) => {
  if (contractStart.kind === "unknown") {
    return "未定・不明";
  } else if (contractStart.kind === "negotiable") {
    return "お問い合わせ";
  }

  const date = new Date(contractStart.date as string);
  const yearMonth = formatMonthStr(contractStart.date as string);
  const now = new Date();

  if (contractStart.kind === "approxDate") {
    // 旬で指定されている場合、当月中については即入居可にしない
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const period = formatContractStartPeriodType(contractStart.period);
    const approxDate = `${yearMonth}${period}`;
    if (date < startOfMonth) {
      return `即入居可 (${approxDate})`;
    }
    return `${approxDate}予定`;
  }

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const exactDate = `${formatDateStr(contractStart.date as string, false)}`;
  if (date < today) {
    return `即入居可 (${exactDate})`;
  }
  return `${exactDate}予定`;
};

export const formatGraphQLContractStart = (
  contractStart: GraphQLContractStart,
) => {
  if (contractStart.kind === GraphQLContractStartType.Unknown) {
    return "未定・不明";
  } else if (contractStart.kind === GraphQLContractStartType.Negotiable) {
    return "お問い合わせ";
  }

  const date = new Date(contractStart.date as string);
  const yearMonth = formatMonthStr(contractStart.date as string);
  const now = new Date();

  if (contractStart.kind === GraphQLContractStartType.ApproxDate) {
    // 旬で指定されている場合、当月中については即入居可にしない
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const period = formatGraphQLAboutContractStartType(contractStart.period);
    const approxDate = `${yearMonth}${period}`;
    if (date < startOfMonth) {
      return `即入居可 (${approxDate})`;
    }
    return `${approxDate}予定`;
  }

  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const exactDate = `${formatDateStr(contractStart.date as string, false)}`;
  if (date < today) {
    return `即入居可 (${exactDate})`;
  }
  return `${exactDate}予定`;
};

export const formatContractStartPeriodType = (
  contractStartPeriod?: "unknown" | "early" | "middle" | "late",
) => {
  if (contractStartPeriod === "early") {
    return "上旬";
  } else if (contractStartPeriod === "middle") {
    return "中旬";
  } else if (contractStartPeriod === "late") {
    return "下旬";
  }
  return "";
};

export const formatGraphQLAboutContractStartType = (
  contractStartPeriod?: Maybe<GraphQLAboutContractStartType>,
) => {
  if (contractStartPeriod === GraphQLAboutContractStartType.Early) {
    return "上旬";
  } else if (contractStartPeriod === GraphQLAboutContractStartType.Middle) {
    return "中旬";
  } else if (contractStartPeriod === GraphQLAboutContractStartType.Late) {
    return "下旬";
  }
  return "";
};

export const formatContractType = (
  apiContractType: "regular" | "fixed" | "other" | undefined,
  contractTypeSideNote?: string,
) => {
  //補足がある場合、契約形態の隣に()で括って表示
  const sideNote = contractTypeSideNote ? `（${contractTypeSideNote}）` : "";

  switch (apiContractType) {
    case "fixed":
      return `定借${sideNote}`;
    case "regular":
      return `普通借${sideNote}`;
    case "other":
      return `その他${sideNote}`;
    default:
      return `お問い合わせ${sideNote}`;
  }
};

export const formatContractPeriod = (
  contractPeriod: {
    date?: string;
    kind: "negotiable" | "duration" | "expiryDate";
    month?: number;
    year?: number;
  },
  isShort: boolean,
): string => {
  if (contractPeriod.kind === "duration") {
    return `${contractPeriod.year}年${contractPeriod.month}ヶ月`;
  } else if (contractPeriod.kind === "expiryDate" && contractPeriod.date) {
    return `〜${formatDateStr(contractPeriod.date, isShort)}`;
  } else {
    return "お問い合わせ";
  }
};

export const formatGraphQLContractType = (
  apiContractType?: GraphQLContractType | null,
) => {
  switch (apiContractType) {
    case GraphQLContractType.Fixed:
      return "定借";
    case GraphQLContractType.Regular:
      return "普通借";
    case GraphQLContractType.Other:
      return "その他";
    default:
      return "お問い合わせ";
  }
};

export const formatGraphQLContractPeriod = (
  contractPeriod: GraphQLContractPeriod,
  isShort: boolean,
): string => {
  if (contractPeriod.kind === GraphQLContractPeriodType.Duration) {
    return `${contractPeriod.year}年${contractPeriod.month}ヶ月`;
  } else if (
    contractPeriod.kind === GraphQLContractPeriodType.ExpiryDate &&
    contractPeriod.date
  ) {
    return `〜${formatDateStr(contractPeriod.date, isShort)}`;
  } else {
    return "お問い合わせ";
  }
};

export const formatLeasingType = (
  leasingType:
    | "lessor"
    | "brokerage"
    | "exclusiveBrokerage"
    | "privilegedExclusiveBrokerage"
    | "agent"
    | undefined,
) => {
  if (!leasingType) {
    return "";
  }
  switch (leasingType) {
    case "lessor":
      return "貸主";
    case "brokerage":
      return "媒介";
    case "exclusiveBrokerage":
      return "専任媒介";
    case "privilegedExclusiveBrokerage":
      return "専属専任媒介";
    case "agent":
      return "代理";
    default: {
      const nv: never = leasingType;
      throw new Error(`unsupported leasing type: ${nv}`);
    }
  }
};

export const formatGraphQLLeasingType = (
  leasingType: GraphQLLeasingType | undefined | null,
) => {
  if (!leasingType) {
    return "";
  }
  switch (leasingType) {
    case GraphQLLeasingType.Lessor:
      return "貸主";
    case GraphQLLeasingType.Brokerage:
      return "媒介";
    case GraphQLLeasingType.ExclusiveBrokerage:
      return "専任媒介";
    case GraphQLLeasingType.PrivilegedExclusiveBrokerage:
      return "専属専任媒介";
    case GraphQLLeasingType.Agent:
      return "代理";
    default: {
      const nv: never = leasingType;
      throw new Error(`unsupported leasing type: ${nv}`);
    }
  }
};

export const formatFloor = (floor: string) => {
  return removeTrailingZeros(floor).replace(/^-/, "B");
};

export const formatFloors = (asking: { units: { floor: string }[] }) => {
  const floors = sortFloors(
    Array.from(
      new Set(asking.units.map((unit) => `${formatFloor(unit.floor)}F`)),
    ),
  );
  return floors.join(", ");
};

export const formatFloorsWithContinuousHandling = (floors: string[]) => {
  const sortedFloors = Array.from(new Set(floors)).sort(
    (a, b) => Number(a) - Number(b),
  );
  const isNeighboring = (floor1: number, floor2: number) =>
    Math.abs(floor1 - floor2) === 1 ||
    (floor1 === -1 && floor2 === 1) ||
    (floor1 === 1 && floor2 === -1);
  const formatBlock = (block: string[]): string => {
    if (block.length >= 3) {
      const firstFloor = `${formatFloor(block[0])}F`;
      const lastFloor = `${formatFloor(block[block.length - 1])}F`;
      return `${firstFloor}～${lastFloor}`;
    } else {
      return block.map((floor) => `${formatFloor(floor)}F`).join(", ");
    }
  };
  const result = [];
  let currentBlock: string[] = [];
  for (const floor of sortedFloors) {
    if (
      !currentBlock.length ||
      isNeighboring(
        Number(currentBlock[currentBlock.length - 1]),
        Number(floor),
      )
    ) {
      currentBlock.push(floor);
    } else {
      result.push(formatBlock(currentBlock));
      currentBlock = [floor];
    }
  }
  if (currentBlock.length > 0) {
    result.push(formatBlock(currentBlock));
  }
  return result.join(", ");
};

export const formatDeposit = (deposit?: {
  total?: number;
  inMonth?: string;
  inMonthTaxIncluded?: boolean;
  inMonthCamFeeIncluded?: boolean;
  valueType: "undefined" | "negotiable" | "none" | "total" | "inMonth";
}) => {
  if (deposit?.valueType === "negotiable") {
    return "お問い合わせ";
  }
  if (deposit?.valueType === "none") {
    return "なし";
  }
  if (deposit?.valueType === "undefined") {
    return "未定・不明";
  }
  if (deposit?.valueType === "total" && isInteger(deposit.total)) {
    return `${deposit.total.toLocaleString("ja-JP")}円`;
  }
  if (deposit?.valueType === "inMonth" && deposit.inMonth) {
    const month = parseFloat(deposit.inMonth);
    if (!Number.isNaN(month)) {
      if (!deposit.inMonthCamFeeIncluded && !deposit.inMonthTaxIncluded) {
        return `${month}ヶ月`;
      } else if (!deposit.inMonthCamFeeIncluded && deposit.inMonthTaxIncluded) {
        return `税込賃料の${month}ヶ月分`;
      } else if (deposit.inMonthCamFeeIncluded && !deposit.inMonthTaxIncluded) {
        return `賃料+共益費の${month}ヶ月分`;
      } else {
        return `税込賃料+共益費の${month}ヶ月分`;
      }
    }
  }
  return "お問い合わせ";
};

export const formatFreeRent = (freeRent?: {
  valueType: "undefined" | "negotiable" | "none" | "inMonth";
  inMonth?: string;
}) => {
  if (freeRent?.valueType === "negotiable") {
    return "お問い合わせ";
  }
  if (freeRent?.valueType === "none") {
    return "なし";
  }
  if (freeRent?.valueType === "undefined") {
    return "ー";
  }
  if (freeRent?.valueType === "inMonth" && freeRent.inMonth) {
    const month = parseFloat(freeRent.inMonth);
    if (!Number.isNaN(month)) {
      return `${month}ヶ月`;
    }
  }
  return "ー";
};

export const formatGraphQLDeposit = (deposit?: GraphQLDeposit) => {
  if (deposit?.valueType === GraphQLDepositType.Negotiable) {
    return "お問い合わせ";
  }
  if (deposit?.valueType === GraphQLDepositType.None) {
    return "なし";
  }
  if (deposit?.valueType === GraphQLDepositType.Undefined) {
    return "未定・不明";
  }
  if (
    deposit?.valueType === GraphQLDepositType.Total &&
    isInteger(deposit.total)
  ) {
    return `${deposit.total.toLocaleString("ja-JP")}円`;
  }
  if (deposit?.valueType === GraphQLDepositType.InMonth && deposit.inMonth) {
    const month = parseFloat(deposit.inMonth);
    if (!Number.isNaN(month)) {
      if (!deposit.inMonthCamFeeIncluded && !deposit.inMonthTaxIncluded) {
        return `${month}ヶ月`;
      } else if (!deposit.inMonthCamFeeIncluded && deposit.inMonthTaxIncluded) {
        return `税込賃料の${month}ヶ月分`;
      } else if (deposit.inMonthCamFeeIncluded && !deposit.inMonthTaxIncluded) {
        return `賃料+共益費の${month}ヶ月分`;
      } else {
        return `税込賃料+共益費の${month}ヶ月分`;
      }
    }
  }
  return "お問い合わせ";
};

export const formatKeyMoney = (keyMoney?: {
  keyMoney?: number;
  keyMoneyInMonth?: string;
}) => {
  if (keyMoney === undefined) {
    return "お問い合わせ";
  }
  if (keyMoney.keyMoney === 0) {
    return "なし";
  }
  if (isInteger(keyMoney.keyMoney)) {
    return `${keyMoney.keyMoney.toLocaleString("ja-JP")}円`;
  }
  if (keyMoney.keyMoneyInMonth) {
    const month = parseFloat(keyMoney.keyMoneyInMonth);
    if (!Number.isNaN(month)) {
      return `${month}ヶ月`;
    }
  }
  return "お問い合わせ";
};

export const formatGraphQLKeyMoney = (keyMoney?: GraphQLKeyMoney | null) => {
  if (keyMoney === undefined || keyMoney === null) {
    return "お問い合わせ";
  }
  if (keyMoney.keyMoney === 0) {
    return "なし";
  }
  if (isInteger(keyMoney.keyMoney)) {
    return `${keyMoney.keyMoney.toLocaleString("ja-JP")}円`;
  }
  if (keyMoney.keyMoneyInMonth) {
    const month = parseFloat(keyMoney.keyMoneyInMonth);
    if (!Number.isNaN(month)) {
      return `${month}ヶ月`;
    }
  }
  return "お問い合わせ";
};

export const formatContractUpdateFee = (contractUpdateFee?: {
  kind?:
    | "deposit"
    | "leaseDeposit"
    | "manual"
    | "negotiable"
    | "none"
    | "rent"
    | "undefined"
    | "warranty";
  manual?: string;
  month?: string;
  ratio?: string;
}) => {
  if (contractUpdateFee === undefined) {
    return undefined;
  }
  const month = parseFloat(contractUpdateFee?.month || "");
  const ratio = parseFloat(contractUpdateFee?.ratio || "");
  switch (contractUpdateFee?.kind) {
    case "rent":
      return `賃料の${month}ヶ月分`;
    case "deposit":
      return `敷金の${ratio}％`;
    case "leaseDeposit":
      return `預託金の${ratio}％`;
    case "warranty":
      return `保証金の${ratio}％`;
    case "manual":
      return contractUpdateFee?.manual;
    case "negotiable":
      return "お問い合わせ";
    case "none":
      return "なし";
    case "undefined":
      return "未定・不明";
    case undefined:
      return undefined;
    default: {
      const nv: never = contractUpdateFee?.kind;
      throw new Error(`unsupported contractUpdateFee.kind: ${nv}`);
    }
  }
};

export const formatRedemption = (redemption?: {
  kind?:
    | "deposit"
    | "leaseDeposit"
    | "manual"
    | "negotiable"
    | "none"
    | "rent"
    | "undefined"
    | "warranty";
  manual?: string;
  month?: string;
  ratio?: string;
}) => {
  if (redemption === undefined) {
    return undefined;
  }
  const month = parseFloat(redemption?.month || "");
  const ratio = parseFloat(redemption?.ratio || "");
  switch (redemption?.kind) {
    case "rent":
      return `賃料の${month}ヶ月分`;
    case "deposit":
      return `敷金の${ratio}％`;
    case "leaseDeposit":
      return `預託金の${ratio}％`;
    case "warranty":
      return `保証金の${ratio}％`;
    case "manual":
      return redemption?.manual;
    case "negotiable":
      return "お問い合わせ";
    case "none":
      return "なし";
    case "undefined":
      return "未定・不明";
    case undefined:
      return undefined;
    default: {
      const nv: never = redemption?.kind;
      throw new Error(`unsupported redemption.kind: ${nv}`);
    }
  }
};

export const formatBrokageFee = (brokageFee?: {
  brokageFeeInMonth?: string;
  brokageFee?: number;
}) => {
  if (brokageFee === undefined) {
    return "お問い合わせ";
  }
  if (brokageFee.brokageFee === 0) {
    return "なし";
  }
  if (brokageFee.brokageFee) {
    return `${brokageFee.brokageFee.toLocaleString("ja-JP")}円`;
  }
  if (brokageFee.brokageFeeInMonth) {
    const month = parseFloat(brokageFee.brokageFeeInMonth);
    if (!Number.isNaN(month)) {
      return `${month}ヶ月`;
    }
  }
  return "お問い合わせ";
};

export const formatStringPeriod = (openDate: string, closeDate?: string) => {
  if (openDate === "") return "-";
  if (closeDate === "" || closeDate === undefined) {
    return `${formatDateStr(openDate, true)}〜`;
  } else {
    return `${formatDateStr(openDate, true)}〜${formatDateStr(
      closeDate,
      true,
    )}`;
  }
};

/**
 * 小数第2位までか、入力から末尾の0を取ったものの長い方
 */
export const formatArea = (s: string): string => {
  const dotPosition = s.indexOf(".");
  if (dotPosition === -1) {
    return `${Number.parseInt(s.replace(/,/g, "")).toLocaleString("ja-JP")}.00`;
  }
  const integer = Number.parseInt(
    s.substring(0, dotPosition).replace(/,/g, ""),
  ).toLocaleString();
  let decimal = s.substring(dotPosition + 1).replace(/0+$/, "");
  if (decimal.length < 2) {
    decimal += "0".repeat(2 - decimal.length);
  }
  return `${integer}.${decimal}`;
};

type ListItemDisplayInfo = {
  helper?: string;
  isConfidential?: boolean;
  value: string;
  indent?: boolean;
};

// 募集条件 - 基本情報
export const askingToContentBasicConditionMap = (asking: {
  roomNumber: string;
  area: string;
  areaSqm: string;
}) => {
  const { roomNumber, area, areaSqm } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push(["部屋番号", { value: roomNumber || "" }]);
  contents.push([
    "募集面積",
    {
      value: `${formatLocaleDecimal(area)}坪（${formatLocaleDecimal(
        areaSqm,
      )}㎡）`,
    },
  ]);
  return contents;
};

// 募集条件 - 料金
export const askingToContentFeeConditionMap = (asking: {
  rent: Parameters<typeof formatRent>[0];
  camFee: Parameters<typeof formatCamFee>[0];
  internalRent: Parameters<typeof formatInternalRent>[0];
  lowerLimitRent: Parameters<typeof formatInternalRent>[0];
  deposit: Parameters<typeof formatDeposit>[0];
  freeRent: Parameters<typeof formatFreeRent>[0];
  keyMoney?: Parameters<typeof formatKeyMoney>[0];
  contractUpdateFee?: Parameters<typeof formatContractUpdateFee>[0];
  redemption?: Parameters<typeof formatRedemption>[0];
}) => {
  const {
    rent,
    camFee,
    internalRent,
    lowerLimitRent,
    deposit,
    freeRent,
    keyMoney,
    contractUpdateFee,
    redemption,
  } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push([
    "募集賃料",
    { helper: "(共益費込)", value: formatRent(rent) },
  ]);
  contents.push(["賃料単価", { value: formatRentUnitPrice(camFee, rent) }]);
  contents.push(["共益費", { value: formatCamFee(camFee) }]);
  contents.push([
    "基準賃料",
    {
      helper: "(共益費込)",
      isConfidential: true,
      value: formatInternalRent(internalRent),
    },
  ]);
  contents.push([
    "下限賃料",
    {
      helper: "(共益費込)",
      isConfidential: true,
      value: formatInternalRent(lowerLimitRent),
    },
  ]);
  contents.push(["敷金/保証金/預託金", { value: formatDeposit(deposit) }]);
  contents.push(["礼金", { value: formatKeyMoney(keyMoney) }]);
  contents.push([
    "更新料",
    { value: formatContractUpdateFee(contractUpdateFee) || "" },
  ]);
  contents.push(["償却", { value: formatRedemption(redemption) || "" }]);
  contents.push(["フリーレント", { value: formatFreeRent(freeRent) }]);
  return contents;
};

// 募集条件 - 契約
export const askingToContentAgreementConditionMap = (asking: {
  brokageFee?: Parameters<typeof formatBrokageFee>[0];
  contractStart: Parameters<typeof formatContractStart>[0];
  leasingType?: Parameters<typeof formatLeasingType>[0];
  unitUses: Parameters<typeof formatApiUnitUses>[0];
  contractType?: Parameters<typeof formatContractType>[0];
  contractTypeSideNote?: string;
  contractPeriod: Parameters<typeof formatContractPeriod>[0];
  isSetupOffice: boolean;
}) => {
  const {
    brokageFee,
    contractStart,
    leasingType,
    unitUses,
    contractType,
    contractTypeSideNote,
    contractPeriod,
    isSetupOffice,
  } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push(["入居可能日", { value: formatContractStart(contractStart) }]);
  contents.push(["使用用途", { value: formatApiUnitUses(unitUses) || "" }]);
  contents.push(["取引形態", { value: formatLeasingType(leasingType) }]);
  contents.push([
    "契約形態",
    {
      value: formatContractType(contractType, contractTypeSideNote),
    },
  ]);
  contents.push([
    "契約期間",
    {
      value: formatContractPeriod(contractPeriod, false),
    },
  ]);
  contents.push(["仲介手数料", { value: formatBrokageFee(brokageFee) }]);
  contents.push([
    "募集タイプ",
    { value: isSetupOffice ? "セットアップオフィス" : "" },
  ]);
  return contents;
};

// 担当者
export const askingToContentManagerMap = <
  T extends {
    managers: {
      displayOrder: number;
      managerCompany?: string;
      managerContact?: string;
      managerName?: string;
    }[];
  },
>(
  asking: T,
) => {
  const { managers } = asking;

  return managers.map(
    (manager) =>
      [
        manager.displayOrder,
        {
          managerCompany: {
            value: manager.managerCompany,
          } as ListItemDisplayInfo,
          managerName: {
            value: manager.managerName,
          } as ListItemDisplayInfo,
          managerContract: {
            value: manager.managerContact,
          } as ListItemDisplayInfo,
        },
      ] as const,
  );
};

// 備考
export const askingToContentExplanationMap = (asking: {
  explanation?: string;
}) => {
  const { explanation } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push(["", { value: explanation || "" }]);
  return contents;
};

// 非公開メモ
export const askingToContentInternalExplanationMap = (asking: {
  internalExplanation?: string;
}) => {
  const { internalExplanation } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push([
    "",
    { value: internalExplanation || "", isConfidential: true },
  ]);
  return contents;
};

// 募集作成日
export const askingToContentInitialCreatedDateMap = (asking: {
  initialCreatedDate: string;
}) => {
  const { initialCreatedDate } = asking;
  const contents = [] as [string, ListItemDisplayInfo][];
  contents.push([
    "",
    {
      value: formatDateStr(initialCreatedDate, false) || "",
      isConfidential: false,
    },
  ]);
  return contents;
};

type Rent = {
  perTsubo?: number;
  rentType: "negotiable" | "perTsubo" | "total";
  total?: number;
};

type CamFee = {
  perTsubo?: number;
  total?: number;
  valueType:
    | "undefined"
    | "negotiable"
    | "perTsubo"
    | "total"
    | "includedInRent"
    | "nothing";
};

export const formatRent = (rent: Rent) => {
  if (rent.rentType === "perTsubo" && isInteger(rent.perTsubo)) {
    return `${formatCommaSeparatedNum(rent.perTsubo)}円/坪`;
  }
  if (rent.rentType === "total" && isInteger(rent.total)) {
    return `${formatCommaSeparatedNum(rent.total)}円/月`;
  }
  return "お問い合わせ";
};

export const formatGraphQLRent = (rent: GraphQLRent) => {
  if (rent.rentType === GraphQLRentType.PerTsubo && isInteger(rent.perTsubo)) {
    return `${formatCommaSeparatedNum(rent.perTsubo)}円/坪`;
  }
  if (rent.rentType === GraphQLRentType.Total && isInteger(rent.total)) {
    return `${formatCommaSeparatedNum(rent.total)}円/月`;
  }
  return "お問い合わせ";
};

export const formatInternalRent = (internalRent: {
  perTsubo?: number;
  rentType: "undefined" | "total" | "perTsubo";
  total?: number;
}) => {
  if (
    internalRent.rentType === "perTsubo" &&
    internalRent.perTsubo !== undefined
  ) {
    return `${internalRent.perTsubo.toLocaleString("ja-JP")}円/坪`;
  }

  if (internalRent.rentType === "total" && internalRent.total !== undefined) {
    return `${internalRent.total.toLocaleString("ja-JP")}円/月`;
  }
  return "未定・不明";
};

export const formatGraphQLInternalRent = (
  internalRent: GraphQLInternalRent,
) => {
  if (
    internalRent.valueType === GraphQLInternalRentType.PerTsubo &&
    internalRent.perTsubo !== undefined &&
    internalRent.perTsubo !== null
  ) {
    return `${internalRent.perTsubo.toLocaleString("ja-JP")}円/坪`;
  }

  if (
    internalRent.valueType === GraphQLInternalRentType.Total &&
    internalRent.total !== undefined &&
    internalRent.total !== null
  ) {
    return `${internalRent.total.toLocaleString("ja-JP")}円/月`;
  }
  return "未定・不明";
};

export const formatRentUnitPrice = (camFee: CamFee, rent: Rent) => {
  if (
    rent.rentType === "perTsubo" &&
    isInteger(rent.perTsubo) &&
    camFee.valueType === "perTsubo" &&
    isInteger(camFee.perTsubo)
  ) {
    return `${formatCommaSeparatedNum(rent.perTsubo - camFee.perTsubo)}円/坪`;
  }
  if (
    rent.rentType === "total" &&
    isInteger(rent.total) &&
    camFee.valueType === "total" &&
    isInteger(camFee.total)
  ) {
    return `${formatCommaSeparatedNum(rent.total - camFee.total)}円/月`;
  }

  return "";
};

export const formatCamFee = (camFee: CamFee) => {
  if (camFee.valueType === "nothing") {
    return "なし";
  }
  if (camFee.valueType === "undefined") {
    return "未定・不明";
  }
  if (camFee.valueType === "negotiable") {
    return "お問い合わせ";
  }
  if (camFee.valueType === "includedInRent") {
    return "賃料に含む";
  }
  if (isInteger(camFee.perTsubo)) {
    return `${formatCommaSeparatedNum(camFee.perTsubo)}円/坪`;
  }
  if (isInteger(camFee.total)) {
    return `${formatCommaSeparatedNum(camFee.total)}円/月`;
  }

  return "";
};

export const formatGraphQLCamFee = (camFee: GraphQLCamFee) => {
  if (camFee.valueType === GraphQLCamFeeType.Nothing) {
    return "なし";
  }
  if (camFee.valueType === GraphQLCamFeeType.Undefined) {
    return "未定・不明";
  }
  if (camFee.valueType === GraphQLCamFeeType.Negotiable) {
    return "お問い合わせ";
  }
  if (camFee.valueType === GraphQLCamFeeType.IncludedInRent) {
    return "賃料に含む";
  }
  if (isInteger(camFee.perTsubo)) {
    return `${formatCommaSeparatedNum(camFee.perTsubo)}円/坪`;
  }
  if (isInteger(camFee.total)) {
    return `${formatCommaSeparatedNum(camFee.total)}円/月`;
  }

  return "";
};

type UnitUseKind =
  | "office"
  | "serviceOffice"
  | "residence"
  | "warehouse"
  | "parking"
  | "restaurant"
  | "merchandiseStore"
  | "clinic"
  | "laboratory"
  | "others";
type UnitUse = {
  kind: UnitUseKind;
  othersContent?: string;
};

export const formatApiUnitUses = (unitUses: Array<UnitUse>): string => {
  return unitUses
    .sort((a, b) => getIndexForUnitUse(a.kind) - getIndexForUnitUse(b.kind))
    .map(formatApiUnitUse)
    .join(", ");
};

const formatApiUnitUse = (u: UnitUse): string => {
  if (u.kind === "others" && u.othersContent) {
    return `${formatUnitUseKind(u.kind)}(${u.othersContent})`;
  }
  return formatUnitUseKind(u.kind);
};

export const formatUnitUseKind = (unitUse: UnitUseKind) => {
  switch (unitUse) {
    case "office":
      return "事務所";
    case "serviceOffice":
      return "サービスオフィス";
    case "residence":
      return "住宅";
    case "warehouse":
      return "倉庫";
    case "parking":
      return "駐車場";
    case "restaurant":
      return "飲食店舗";
    case "merchandiseStore":
      return "物販店舗";
    case "clinic":
      return "診療所";
    case "laboratory":
      return "研究所";
    case "others":
      return "その他";
    default: {
      const nev: never = unitUse;
      throw new Error(`unsupported leasing type: ${nev}`);
    }
  }
};

// 見た目の順番を固定するためのIndex,
export const getIndexForUnitUse = (unitUse: UnitUseKind) => {
  switch (unitUse) {
    case "office":
      return 1;
    case "serviceOffice":
      return 2;
    case "residence":
      return 3;
    case "warehouse":
      return 4;
    case "parking":
      return 5;
    case "restaurant":
      return 6;
    case "merchandiseStore":
      return 7;
    case "clinic":
      return 8;
    case "laboratory":
      return 9;
    case "others":
      return 99;
    default: {
      const nev: never = unitUse;
      throw new Error(`unsupported leasing type: ${nev}`);
    }
  }
};

export const formatGraphQLUnitUses = (
  unitUses: Array<GraphQLUnitUse>,
): string => {
  return unitUses
    .sort(
      (a, b) =>
        getIndexForGraphQLUnitUse(a.kind) - getIndexForGraphQLUnitUse(b.kind),
    )
    .map(formatGraphQLUnitUse)
    .join(", ");
};

const formatGraphQLUnitUse = (u: GraphQLUnitUse): string => {
  if (u.kind === GraphQLUnitUseElement.Others && u.othersContent) {
    return `${formatGraphQLUnitUseKind(u.kind)}(${u.othersContent})`;
  }
  return formatGraphQLUnitUseKind(u.kind);
};

export const formatGraphQLUnitUseKind = (unitUse: GraphQLUnitUseElement) => {
  switch (unitUse) {
    case GraphQLUnitUseElement.Office:
      return "事務所";
    case GraphQLUnitUseElement.ServiceOffice:
      return "サービスオフィス";
    case GraphQLUnitUseElement.Residence:
      return "住宅";
    case GraphQLUnitUseElement.Warehouse:
      return "倉庫";
    case GraphQLUnitUseElement.Parking:
      return "駐車場";
    case GraphQLUnitUseElement.Restaurant:
      return "飲食店舗";
    case GraphQLUnitUseElement.MerchandiseStore:
      return "物販店舗";
    case GraphQLUnitUseElement.Clinic:
      return "診療所";
    case GraphQLUnitUseElement.Laboratory:
      return "研究所";
    case GraphQLUnitUseElement.Others:
      return "その他";
    default: {
      const nev: never = unitUse;
      throw new Error(`unsupported leasing type: ${nev}`);
    }
  }
};

// 見た目の順番を固定するためのIndex,
export const getIndexForGraphQLUnitUse = (unitUse: GraphQLUnitUseElement) => {
  switch (unitUse) {
    case GraphQLUnitUseElement.Office:
      return 1;
    case GraphQLUnitUseElement.ServiceOffice:
      return 2;
    case GraphQLUnitUseElement.Residence:
      return 3;
    case GraphQLUnitUseElement.Warehouse:
      return 4;
    case GraphQLUnitUseElement.Parking:
      return 5;
    case GraphQLUnitUseElement.Restaurant:
      return 6;
    case GraphQLUnitUseElement.MerchandiseStore:
      return 7;
    case GraphQLUnitUseElement.Clinic:
      return 8;
    case GraphQLUnitUseElement.Laboratory:
      return 9;
    case GraphQLUnitUseElement.Others:
      return 99;
    default: {
      const nev: never = unitUse;
      throw new Error(`unsupported leasing type: ${nev}`);
    }
  }
};

export const formatPublishOnHomepage = (publishOnHomepage?: boolean) => {
  switch (publishOnHomepage) {
    case undefined:
      return "";
    case true:
      return "有";
    case false:
      return "無";
    default: {
      const nv: never = publishOnHomepage;
      throw new Error(`unsupported publishOnHomepage: ${nv}`);
    }
  }
};

export const formatCommaSeparatedNum = (num: number) =>
  num.toLocaleString("ja-JP");
const isInteger = (x: unknown): x is number => {
  return Number.isInteger(x);
};
