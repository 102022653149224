import Decimal from "decimal.js";

export const parseNonNegDecimal = (value: string) => {
  try {
    const decimal = new Decimal(value);
    if (!decimal.isNaN() && !decimal.isNeg()) {
      return decimal;
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeTrailingZeros = (floorDecimalString: string) => {
  try {
    return new Decimal(floorDecimalString).toFixed();
  } catch {
    return floorDecimalString;
  }
};

export const formatLocaleDecimal = (value: string | Decimal, maxDigits = 3) => {
  try {
    const originalDecimal = new Decimal(value);
    const sign = originalDecimal.isNeg() ? "-" : "";
    const absDecimal = originalDecimal.abs();
    const absIntegerPart = absDecimal.floor();
    const formattedAbsIntegerPart = absIntegerPart
      .toNumber()
      .toLocaleString("ja-JP");
    // 絶対値の小数点以下の値。5.2 → 0.2, -3.6 → 0.6
    const absFractionalPart = absDecimal.minus(absIntegerPart);
    const formattedFractionalPart = absFractionalPart
      .toFixed(maxDigits, Decimal.ROUND_DOWN)
      .replace(/0+$/, "")
      // "0." を削除する
      .slice(2);
    if (absFractionalPart.gt(new Decimal(0))) {
      return `${sign}${formattedAbsIntegerPart}.${formattedFractionalPart}`;
    }
    return `${sign}${formattedAbsIntegerPart}`;
  } catch (error) {
    if (isNaN(Number(value))) {
      return value.toString();
    }
    return Number(value).toLocaleString("ja-JP");
  }
};
