export const formatDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");
  return `${year}-${monthStr}-${dayStr}`;
};

export const formatDateStr = (dateStr: string, yearLast2Digits: boolean) => {
  const date = new Date(dateStr);
  const year = yearLast2Digits ? date.getFullYear() % 100 : date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");

  return `${year}/${monthStr}/${dayStr}`;
};

export const formatMonthStr = (dateStr: string) => {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const monthStr = month.toString().padStart(2, "0");

  return `${year}/${monthStr}`;
};

export const formatDateTimeStr = (dateTimeStr: string) => {
  const date = new Date(dateTimeStr);
  const year = date.getFullYear() % 100;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minuite = date.getMinutes();

  const monthStr = month.toString().padStart(2, "0");
  const dayStr = day.toString().padStart(2, "0");
  const hourStr = hour.toString().padStart(2, "0");
  const minuiteStr = minuite.toString().padStart(2, "0");

  return `${year}/${monthStr}/${dayStr} ${hourStr}:${minuiteStr}`;
};
